import React, { CSSProperties } from "react";

type KeyProps = {
  type: "white" | "black" | "whiteSelected" | "blackSelected";
  note: string;
  displayName: string;
};

const PianoKeyForScale: React.FC<KeyProps> = ({ displayName, type, note }) => {
  return <div className={`pianoKey ${type}`}>{displayName}</div>;
};

export default PianoKeyForScale;
