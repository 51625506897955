export type GlossaryTerm = {
    word: string;
    definition: string;
};

export type GlossaryTerms = {
    [key: string]: GlossaryTerm;
};

export const glossaryTerms: GlossaryTerms = {
    pitch: {
        word: 'Pitch',
        definition: 'The perceived frequency of a sound; how high or low a note sounds.'
    },
    clef: {
        word: 'Clef',
        definition: 'A symbol indicating which pitches are represented by the lines and spaces of a staff. Common clefs include treble, bass, alto, and tenor.'
    },
    scale: {
        word: 'Scale',
        definition: 'A series of musical notes arranged in ascending or descending order.'
    },
    key: {
        word: 'Key',
        definition: 'The central note and mode on which a piece of music is based.'
    },
    interval: {
        word: 'Interval',
        definition: 'The distance between two pitches.'
    },
    chord: {
        word: 'Chord',
        definition: 'A combination of three or more notes played at the same time.'
    },
    triad: {
        word: 'Triad',
        definition: 'A chord made up of three notes, typically the first (root), third, and fifth note of a scale.'
    },
    dynamics: {
        word: 'Dynamics',
        definition: 'Indicators that tell how loud or soft the music should be played.'
    },
    timeSignature: {
        word: 'Time Signature',
        definition: 'A notational convention specifying how many beats are in each measure and what type of note receives one beat.'
    },
    tempo: {
        word: 'Tempo',
        definition: 'The speed or pace of a musical piece.'
    },
    rhythm: {
        word: 'Rhythm',
        definition: 'The pattern of long and short sounds as they move through time.'
    },
    melody: {
        word: 'Melody',
        definition: 'A sequence of musical notes that are perceived as a single entity.'
    },
    harmony: {
        word: 'Harmony',
        definition: 'The combination of different musical notes played simultaneously to produce chords.'
    },
    arpeggio: {
        word: 'Arpeggio',
        definition: 'Playing the notes of a chord consecutively, rather than simultaneously.'
    },
    cadence: {
        word: 'Cadence',
        definition: 'A sequence of chords that brings a phrase, section, or piece of music to a conclusion.'
    },
    tonic: {
        word: 'Tonic',
        definition: 'The first note of a scale; the "home" note around which a piece is centered.'
    },
    dominant: {
        word: 'Dominant',
        definition: 'The fifth note of a scale, it often demands resolution back to the tonic.'
    },
    modulation: {
        word: 'Modulation',
        definition: 'The process of changing from one key to another within a piece of music.'
    },
    resolution: {
        word: 'Resolution',
        definition: 'The movement from a dissonant chord or note to a consonant one.'
    },
    dissonanceConsonance: {
        word: 'Dissonance/Consonance',
        definition: 'Terms used to describe intervals or chords that sound tense and in need of resolution versus those that sound stable and at rest.'
    },
    legatoStaccato: {
        word: 'Legato/Staccato',
        definition: 'Musical articulations; legato means to play notes smoothly, while staccato means to play them short and detached.'
    },
    phrase: {
        word: 'Phrase',
        definition: 'A musical unit, often a component of a melody.'
    },
    syncopation: {
        word: 'Syncopation',
        definition: 'A rhythm that puts emphasis on weak beats or weak parts of beats.'
    },
    transpose: {
        word: 'Transpose',
        definition: 'To shift a piece of music to a different pitch level.'
    },
    timbre: {
        word: 'Timbre',
        definition: 'Also known as tone color, it refers to the quality or color of a sound, helping us distinguish different instruments or voices.'
    }
};
