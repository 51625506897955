import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

type GlossaryCardProps = {
  word: string;
  definition: string;
};

const GlossaryCard: React.FC<GlossaryCardProps> = ({ word, definition }) => {
  const [expanded, setExpanded] = useState(false);

  // const dialog: any = document.getElementById("d");

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // const handleDialogOpen = () => {
  //   dialog?.showModal();
  // };

  // const handleDialogClose = () => {
  //   dialog?.close();
  // };

  return (
    <Card
      sx={{
        width: 320,
        backgroundColor: "#282c34",
        borderRadius: "1rem",
        border: "1px solid whitesmoke",
        borderColor: "#93f6fa",
        fontFamily: "inherit",
      }}
    >
      {/* <dialog id="d">
        <p>hello</p>
        <Button size="small" onClick={handleDialogClose}>
          CLOSE
        </Button>
      </dialog> */}

      <CardContent sx={{ paddingTop: "3rem" }}>
        <Typography variant="h5" color="whiteSmoke">
          {word}
        </Typography>
      </CardContent>

      <ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
        sx={{ color: "#93f6fa" }}
      >
        <ExpandMoreIcon />
      </ExpandMore>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            sx={{
              color: "whitesmoke",
              marginBlockEnd: "1rem",
              fontFamily: "inherit",
            }}
          >
            {definition}
          </Typography>
          {/* <Button size="small" onClick={handleDialogOpen}>
            Learn More
          </Button> */}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default GlossaryCard;
