import "./App.scss";
import FooterComponent from "./components/Footer/Footer";
import Glossary from "./components/Glossary/Glossary";
import SwipableMenu from "./components/Menu/Menu";
import Piano from "./components/Piano/PianoDupe";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta
          property="og:image"
          content="https://rose-realistic-salamander-773.mypinata.cloud/ipfs/QmaSZqaxZx1QWnwER9BJvCe7fr3j3qMTh6mq5t6UKSvRVm"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <header className="header-wrapper">
        <h1> Key Scale Finder</h1>
        <SwipableMenu />
      </header>
      <main className="container">
        <Routes>
          <Route path="/" element={<Piano />} />
          {/* <Route path="/:id" element={<h2>detail page</h2>} /> */}
          <Route path="/glossary" element={<Glossary />} />
        </Routes>
        {/* <Piano /> */}
      </main>
      <FooterComponent />
    </div>
  );
}

export default App;
