// =======================
// Variable shortcuts for musical symbols
// =======================
export const sharpSymbol = String.fromCharCode("0x266F");
export const flatSymbol = String.fromCharCode("0x266D");
export const natSymbol = String.fromCharCode("0x266E");

// =======================
// Array of piano key objects
// =======================
export const pianoKeysArr = [
  {
    displayName: "C",
    noteName: "B# C",
    color: "white",
    id: 1,
    selected: false,
  },
  {
    displayName: `C${sharpSymbol} D${flatSymbol}`,
    noteName: "C# Db",
    color: "black",
    id: 2,
    selected: false,
  },
  {
    displayName: "D",
    noteName: "D",
    color: "white",
    id: 3,
    selected: false,
  },
  {
    displayName: `D${sharpSymbol} E${flatSymbol}`,
    noteName: "D# Eb",
    color: "black",
    id: 4,
    selected: false,
  },
  {
    displayName: "E",
    noteName: "E Fb",
    color: "white",
    id: 5,
    selected: false,
  },
  {
    displayName: "F",
    noteName: "E# F",
    color: "white",
    id: 6,
    selected: false,
  },
  {
    displayName: `F${sharpSymbol} G${flatSymbol}`,
    noteName: "F# Gb",
    color: "black",
    id: 7,
    selected: false,
  },
  {
    displayName: "G",
    noteName: "G",
    color: "white",
    id: 8,
    selected: false,
  },
  {
    displayName: `G${sharpSymbol} A${flatSymbol}`,
    noteName: "G# Ab",
    color: "black",
    id: 9,
    selected: false,
  },
  {
    displayName: "A",
    noteName: "A",
    color: "white",
    id: 10,
    selected: false,
  },
  {
    displayName: `A${sharpSymbol} B${flatSymbol}`,
    noteName: "A# Bb",
    color: "black",
    id: 11,
    selected: false,
  },
  {
    displayName: "B",
    noteName: "B Cb",
    color: "white",
    id: 12,
    selected: false,
  },
];

// =======================
// Array of key signatures
// =======================
export const keySigArr = [
  {
    majorKey: "C",
    relativeMinor: "A",
    notes: ["C", "D", "E", "F", "G", "A", "B"],
    id: 1
  },
  {
    majorKey: "G",
    relativeMinor: "E",
    notes: ["G", "A", "B", "C", "D", "E", "F#"],
    id: 2
  },
  {
    majorKey: "D",
    relativeMinor: "B",
    notes: ["D", "E", "F#", "G", "A", "B", "C#"],
    id: 3
  },
  {
    majorKey: "A",
    relativeMinor: "F#",
    notes: ["A", "B", "C#", "D", "E", "F#", "G#"],
    id: 4
  },
  {
    majorKey: "E",
    relativeMinor: "C#",
    notes: ["E", "F#", "G#", "A", "B", "C#", "D#"],
    id: 5
  },
  {
    majorKey: "B",
    relativeMinor: "G#",
    notes: ["B", "C#", "D#", "E", "F#", "G#", "A#"],
    id: 6
  },
  {
    majorKey: "F#",
    relativeMinor: "D#",
    notes: ["F#", "G#", "A#", "B", "C#", "D#", "E#"],
    id: 7
  },
  {
    majorKey: "C#",
    relativeMinor: "A#",
    notes: ["C#", "D#", "E#", "F#", "G#", "A#", "B#"],
    id: 8
  },
  {
    majorKey: "F",
    relativeMinor: "D",
    notes: ["F", "G", "A", "Bb", "C", "D", "E"],
    id: 9
  },
  {
    majorKey: "Bb",
    relativeMinor: "G",
    notes: ["Bb", "C", "D", "Eb", "F", "G", "A"],
    id: 10
  },
  {
    majorKey: "Eb",
    relativeMinor: "C",
    notes: ["Eb", "F", "G", "Ab", "Bb", "C", "D"],
    id: 11
  },
  {
    majorKey: "Ab",
    relativeMinor: "F",
    notes: ["Ab", "Bb", "C", "Db", "Eb", "F", "G"],
    id: 12
  },
  {
    majorKey: "Db",
    relativeMinor: "B",
    notes: ["Db", "Eb", "F", "Gb", "Ab", "Bb", "C"],
    id: 13
  },
  {
    majorKey: "Gb",
    relativeMinor: "Eb",
    notes: ["Db", "Eb", "F", "Gb", "Ab", "Bb", "Cb"],
    id: 14
  },
  {
    majorKey: "Cb",
    relativeMinor: "Ab",
    notes: ["Cb", "Db", "Eb", "Fb", "Gb", "Ab", "Bb"],
    id: 15
  },
];
