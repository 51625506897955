import React, { CSSProperties } from "react";

type KeyProps = {
  type: "white" | "black" | "whiteSelected" | "blackSelected";
  note: string;
  displayName: string;
  onClick: (note: string) => void;
};

const PianoKey: React.FC<KeyProps> = ({ displayName, type, note, onClick }) => {

  return (
     <div onClick={() => onClick(note)} className={`pianoKey ${type}`}>
     {displayName}
   </div>
  );
};

export default PianoKey;
