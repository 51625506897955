import  { Fragment, useCallback, useEffect, useState } from "react";
// import {useNavigate} from 'react-router-dom'
import PianoKey from "../PianoKey/PianoKey";
import "./Piano.scss";

import { keySigArr, pianoKeysArr, flatSymbol, sharpSymbol } from "../../scales/scalesDupe";
import PianoKeyForScale from "../PianoKey/PianoKeyForScale";


const Piano = () => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [potentialKeys, setPotentialKeys] =useState([]);
  // const navigate = useNavigate();
    //takes array of potential keys generated by "getPotentialKeys" and for each key constructed a module of a piano that starts on the root note of that key.
    const generateKeyModels = useCallback((keySigs) =>{
      keySigs.forEach(keySig=>{
        keySig.piano=buildPianoFromRoot(keySig.notes[0]);
        for(let i=0;i<keySig.piano.length;i++){
          for(let j=0;j<keySig.notes.length;j++){
            if(keySig.piano[i].noteName.includes(" ")){
              if(keySig.piano[i].noteName.split(" ").some(elem=>elem===keySig.notes[j])){
                keySig.piano[i].selected = true;
               keySig.piano[i].displayName = keySig.notes[j];
              if(keySig.piano[i].displayName.includes('b')){
                keySig.piano[i].displayName=keySig.piano[i].displayName.replace('b', flatSymbol) 
              }
              if(keySig.piano[i].displayName.includes('#')){
                keySig.piano[i].displayName=keySig.piano[i].displayName.replace('#', sharpSymbol) 
              }
              }
            }
            else if(keySig.piano[i].noteName.includes(keySig.notes[j])){
              keySig.piano[i].selected = true;
              keySig.piano[i].displayName = keySig.notes[j];
            }
          }
        }
      });
      return keySigs;
    }, [])

  useEffect(() => {
    if (selectedKeys.length > 0) {
      setPotentialKeys(generateKeyModels(getPotentialKeys(selectedKeys)));
    } else {
      setPotentialKeys([]);
    }
  }, [selectedKeys, generateKeyModels]);

// ==========
// USER INPUT
// ==========
  const handleKeyClick = (note) => {
    setSelectedKeys((prevKeys) => {
      if (prevKeys.includes(note)) {
        return prevKeys.filter((key) => key !== note);
      } else {
        return [...prevKeys, note];
      }
    });
  };

  const clearSelectedKeys = () => {
    setSelectedKeys([]);
    setPotentialKeys([]);
  };

  //==========
  //Filtering and piano building logic
  //==========

  //takes note name as a starting point then lists every note up until the octave from that point
  const buildPianoFromRoot=(rootNote)=>{
      let localKeys=[...pianoKeysArr];
      let rootObject = localKeys.find(({noteName})=>noteName.split(" ").includes(rootNote));
      let startingIndex=localKeys.findIndex(note=>note.noteName===rootObject.noteName);
      let pianoKeys = [];
      for(let i=0; i<localKeys.length;i++){
        pianoKeys.push({...localKeys[startingIndex]});
        startingIndex=(startingIndex===11)?0:startingIndex+1;
      }
      return pianoKeys;
    }
  // takes selected keys, for each scale in the scale array, evaluates whether or not the "notes" property contains EVERY selected note so far. if so, it pushes it to a potentialKeys array and returns that array.
  const getPotentialKeys = (selectedKeys) => {
    let potentialKeys=[]
    for(let i=0; i<keySigArr.length;i++){
      if(selectedKeys.every((note)=>{
          if(note.includes(' ')){
            const equivalentNotes = note.split(" ");
            return equivalentNotes.some(note=>keySigArr[i].notes.includes(note))
          } else{
            return keySigArr[i].notes.includes(note);
          }
      })){
        potentialKeys.push(keySigArr[i]);
      }
    }
    return potentialKeys;
  };


  // const navigateToKeySigDetailPage = (keySig)=>{
  //   navigate(`/${keySig.id}`);
  // }


  const renderedPotential = potentialKeys.map(keySig=>{
    return(

    <div className="rendered-scale" key={keySig.majorKey} >
      <h2><span>{keySig.majorKey} major</span><br/>{keySig.relativeMinor} minor</h2>
      <div className="piano-scale-container">
      <div className="piano-scale-wrapper">
        {
          keySig.piano.map(pianoKey=>{
            return(
              <PianoKeyForScale
              type={`${pianoKey.color}${pianoKey.selected?'Selected':''}`}
              note={pianoKey.noteName}
              displayName={pianoKey.selected?pianoKey.displayName:''}
            />
            )
          })
        }
          </div>

      </div>
    </div>

    )
    
  })

return (
  <Fragment>
    <div className="piano-main-container">

      <div className="piano-input-wrapper">
      {
        pianoKeysArr.map(key=> <PianoKey
        displayName={key.displayName}
          type={selectedKeys.includes(key.noteName) ? `${key.color}Selected` : key.color}
          note={key.noteName}
          onClick={handleKeyClick}
        />)
      } 
      
      </div>
      <div className="button-and-prompt-wrapper">
        {selectedKeys.length > 0 ? (
          <button className="clear-button" onClick={clearSelectedKeys}>
            CLEAR PIANO
          </button>
        ) : (
          <p className="select-keys-prompt">
            Press any piano key to begin.{" "}
          </p>
        )}
      </div>
    </div>
      <div className={potentialKeys.length?"potentialList":"potentialList hide"}>{renderedPotential}</div>

  </Fragment>
);
};

export default Piano;
