// import { useState } from "react";
// import { getWikipediaContent } from "./wikiAPI";
// import WikipediaContent from "./WikipediaContent";
import "./Glossary.scss";
import { GlossaryTerm, glossaryTerms } from "./GlossaryTerms";
import GlossaryCard from "./GlossaryCard";

const Glossary = () => {
  // const [wikiData, setWikiData] = useState({} as any);

  // const handleGetWikipediaContent = () => {
  //   getWikipediaContent("Scale_(music)").then((data) => {
  //     console.log(data);
  //     setWikiData(data);
  //   });
  // };

  return (
    <div className="glossary-container">
      <h2>Musical Glossary</h2>
      {Object.values(glossaryTerms).map((entry: GlossaryTerm) => (
        <div className="glossary-card" key={entry.word}>
          <GlossaryCard word={entry.word} definition={entry.definition} />
        </div>
      ))}
      {/* <button onClick={handleGetWikipediaContent}>Scale</button>
      <div className="glossary-wiki-content-wrapper">
        <WikipediaContent htmlContent={wikiData.introText} />
        <img alt="wiki" src={wikiData.imageUrl} />
      </div> */}
    </div>
  );
};

export default Glossary;
