import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import "./Menu.scss"

export default function SwipableMenu() {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleDrawer = (open) => (event) => {
    console.log("toggleDrawer", open);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
}                       

    setOpenMenu(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer( false)}
      onKeyDown={toggleDrawer( false)}
      className="menu-drop-down-box"
    >
        <List sx={{background: "lightGrey"}} onClick={toggleDrawer(false)}>
       
            <ListItem disablePadding>   
                <ListItemButton>
                <ListItemIcon>
                     <CloseIcon />        
                   </ListItemIcon>
                    <ListItemText primary={"Menu"} />
                </ListItemButton>
            </ListItem>
        </List>
      
      <Divider />
      <List>
      
            <div className="menu-list-items">
             <Link to="/" className="menu-link">
             <ListItem disablePadding>
               <ListItemButton>
                   <ListItemIcon>
                       🎹            
                   </ListItemIcon>
                 <ListItemText primary={"Piano"} />
               </ListItemButton>
             </ListItem>
             </Link>
            <Link to="/glossary" className="menu-link">
          <ListItem  disablePadding>
            <ListItemButton>
                <ListItemIcon>
                    📕             
                </ListItemIcon>
              <ListItemText primary={"Musical Glossary"} />
            </ListItemButton>
          </ListItem>
          </Link>
          </div>
        
      </List>
    </Box>
  );

  return (
    <div>
      {
        <Fragment>
          <div className="swipable-menu-link-wrapper">
            <Link className="menu-icon" onClick={toggleDrawer(true)}> <MenuIcon /> </Link>
          </div>
          <SwipeableDrawer
        anchor={window.innerWidth < 600 ? "bottom" : "left"}
            open={openMenu}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            // className={"menu"}

          >
        {list("left")}
          </SwipeableDrawer>
        </Fragment>
      }
    </div>
  );
}

